import { useEffect, useState } from 'react';
import { useAnimation } from 'framer-motion';

const useScrollAnimations = (ref) => {
  const controls = useAnimation();
  const [scrollDirection, setScrollDirection] = useState('');
  const [isSectionInView, setIsSectionInView] = useState(false);
  const [initialY, setInitialY] = useState(50);

  const handleScroll = () => {
    const sectionOffsetTop = ref.current.offsetTop;

    if (window.scrollY > sectionOffsetTop) {
      setScrollDirection('down');
      setInitialY(-50);
    } else {
      setScrollDirection('up');
      setInitialY(50);
    }
  };

  useEffect(() => {
    const sectionRef = ref.current;

    const observer = new IntersectionObserver(
      ([entry]) => {
        setIsSectionInView(entry.isIntersecting);
      },
      { threshold: 0 } // Adjust the threshold as needed
    );

    if (sectionRef) {
      observer.observe(sectionRef);
    }

    return () => {
      if (sectionRef) {
        observer.unobserve(sectionRef);
      }
    };
  }, [ref]);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [ref]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (isSectionInView) {
      controls.start({ y: 0, opacity: 1 });
    } else {
      if ((scrollDirection === 'down' && window.scrollY > ref.current.offsetTop) || (scrollDirection === 'up' && window.scrollY < ref.current.offsetTop)) {
        controls.start({ y: initialY });
      }
    }
  }, [isSectionInView, scrollDirection, controls, initialY, ref]);

  return { controls };
};

export default useScrollAnimations;
