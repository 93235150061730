import React, { useRef } from 'react';
import { motion } from 'framer-motion';
import useScrollAnimations from '../../utils/hooks';
import './portfolio.css';

export default function PorfolioCard({ elem, image, title, category }) {
  const card_ref = useRef(null);
  const { controls } = useScrollAnimations(card_ref);

  return (
    <motion.div ref={card_ref} className="work__card" initial={{ y: -50 }} animate={controls} transition={{ duration: 0.5 }}>
      <div className="work__thumbnail">
        <img src={image} alt="" className="work__img" />
        <div className="work__mask"></div>
      </div>
      <div className="work__category_wrapper">
        {category.map((cat, index) => (
          <span key={index} className="work__category">
            {cat}
          </span>
        ))}
      </div>
      {/* <span className="work__category">{category}</span> */}
      <h3 className="work__title">{title}</h3>
      {/* <a href={elem.url} target="_blank" rel="noreferrer" className="work__button">
        <i className="fa-solid fa-arrow-up-right-from-square"></i> */}
      {/* <i className="icon-link work__button-icon"></i> */}
      {/* </a> */}
    </motion.div>
  );
}
