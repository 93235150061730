import React, { useState, useRef } from 'react';
import { motion } from 'framer-motion';
import useScrollAnimations from '../../utils/hooks';
import './portfolio.css';
import Menu from './Menu';
import PorfolioCard from './PorfolioCard';

const Portfolio = () => {
  // const [items, setItems] = useState(Menu);
  const [items] = useState(Menu);
  const header_ref = useRef(null);
  // const filters_ref = useRef(null);

  // const filterItem = (categoryItem) => {
  //   const updatedItems = Menu.filter((curElem) => {
  //     return curElem.category.includes(categoryItem);
  //   });

  //   setItems(updatedItems);
  // };

  const { controls: controls_header } = useScrollAnimations(header_ref);
  // const { controls: controls_filters } = useScrollAnimations(filters_ref);

  return (
    <section className="work container section" id="work">
      <motion.h2 ref={header_ref} className="section__title" initial={{ y: -50 }} animate={controls_header} transition={{ duration: 0.5 }}>
        Recent Works
      </motion.h2>

      {/* <motion.div ref={filters_ref} className="work__filters" initial={{ y: -50 }} animate={controls_filters} transition={{ duration: 0.5 }}>
        <span className="work__item" onClick={() => setItems(Menu)}>
          All
        </span>
        <span className="work__item" onClick={() => filterItem('Games')}>
          Games
        </span>
        <span className="work__item" onClick={() => filterItem('Mobile')}>
          Mobile
        </span>
        <span className="work__item" onClick={() => filterItem('Cloud')}>
          Cloud
        </span> */}
      {/* <span className="work__item" onClick={() => filterItem('Design')}>
          Design
        </span> */}
      {/* </motion.div> */}

      <div className="work__container grid">
        {items.map((elem) => {
          const { id, image, title, category } = elem;
          const cardInfo = { elem, image, title, category };

          return <PorfolioCard key={id} {...cardInfo} />;
        })}
      </div>
    </section>
  );
};

export default Portfolio;
