import React from 'react';
import './services.css';
import Image1 from '../../assets/service-1.svg';
import Image2 from '../../assets/service-2.svg';
import Image3 from '../../assets/service-3.svg';
import ServiceCard from './ServiceCard';

const data = [
  {
    id: 1,
    image: Image2,
    title: 'Lifelong Learner',
    description:
      "Driven by a love for continuous learning and personal growth, I find joy in the process of discovery. It's not always about claiming expertise but relishing the beauty of curiosity and the never-ending adventure of learning.",
  },
  {
    id: 2,
    image: Image1,
    title: 'Cloud Enthusiast',
    description:
      // 'Traversing the skies of cloud computing, my newfound passion is exploring the horizon of cloud solutions. My pursuit includes acquiring proficiency in constructing scalable, resilient infrastructures.',
      "A newfound passion of mine is exploring the horizon of cloud solutions. I've set off on a cloud quest to acquire proficiency in constructing scalable, resilient infrastructures. Feel free to reach out if you're looking to move your project to the cloud.",
  },
  {
    id: 3,
    image: Image3,
    title: 'Disc Golfer',
    description:
      // 'Beyond the screen, you will most likely find me on the disc golf course. A balance of course management, decision making, and mastering the subtleties of each shot. Much like navigating a challenging course, coding involves strategic planning, precision, and refining the smallest details to craft solutions that soar beyond expectations.',
      'Beyond the screen, you will most likely find me on the disc golf course. Much like coding, navigating a challenging course involves strategic planning, precision, and refining small details. Each round presents a new puzzle to solve, necessitating adept course management and mastering the subtleties of each shot.',
  },
];

const Services = () => {
  return (
    <section className="services container section" id="services">
      <div className="services__container grid">
        {data.map(({ id, image, title, description }) => {
          const serviceInfo = { image, title, description };
          return <ServiceCard key={id} {...serviceInfo} />;
        })}
      </div>
    </section>
  );
};

export default Services;
