import React, { useRef } from 'react';
import { motion } from 'framer-motion';
import useScrollAnimations from '../../utils/hooks';
import './services.css';

export default function ServiceCard({ image, title, description }) {
  const service_ref = useRef(null);
  const { controls } = useScrollAnimations(service_ref);

  return (
    <motion.div ref={service_ref} className="services__card" initial={{ y: -50 }} animate={controls} transition={{ duration: 0.5 }}>
      <img src={image} alt="" className="services__img" />
      <h3 className="services__title">{title}</h3>
      <p className="services__description">{description}</p>
    </motion.div>
  );
}
