import React, { useRef } from 'react';
import { motion } from 'framer-motion';
import { useForm } from 'react-hook-form';
import useScrollAnimations from '../../utils/hooks';
import './contact.css';

const Contact = () => {
  const { register, handleSubmit } = useForm();

  const onSubmit = (data) => {
    const body = `${encodeURIComponent(data.message)}%0D%0A%0D%0A(${data.email})`;

    console.log(body);
    console.log(encodeURIComponent(body));

    window.location.href = `mailto:matonmichaelj@gmail.com?subject=${data.subject}&body=${body}`;
  };

  const header_ref = useRef(null);
  const title_ref = useRef(null);
  const details_ref = useRef(null);
  const name_ref = useRef(null);
  const email_ref = useRef(null);
  const subject_ref = useRef(null);
  const message_ref = useRef(null);
  const button_ref = useRef(null);

  const { controls: controls_header } = useScrollAnimations(header_ref);
  const { controls: controls_title } = useScrollAnimations(title_ref);
  const { controls: controls_details } = useScrollAnimations(details_ref);
  const { controls: controls_name } = useScrollAnimations(name_ref);
  const { controls: controls_email } = useScrollAnimations(email_ref);
  const { controls: controls_subject } = useScrollAnimations(subject_ref);
  const { controls: controls_message } = useScrollAnimations(message_ref);
  const { controls: controls_button } = useScrollAnimations(button_ref);

  return (
    <section className="contact container section" id="contact">
      <motion.h2 ref={header_ref} className="section__title" initial={{ y: -50 }} animate={controls_header} transition={{ duration: 0.5 }}>
        Have a Question?
      </motion.h2>

      <div className="contact__container grid">
        <div className="contact__info">
          <motion.h3 ref={title_ref} className="contact__title" initial={{ y: -50 }} animate={controls_title} transition={{ duration: 0.5 }}>
            Let's collaborate!
          </motion.h3>
          <motion.p ref={details_ref} className="contact__details" initial={{ y: -50 }} animate={controls_details} transition={{ duration: 0.5 }}>
            I'm all ears for new ideas and ready to bring them to life. Whether it's a new venture or a creative challenge. Reach out and let's turn those ideas into something extraordinary!
          </motion.p>
        </div>

        <form onSubmit={handleSubmit(onSubmit)} className="contact__form">
          <div className="contact__form-group">
            <motion.div ref={name_ref} className="contact__form-div" initial={{ y: -50 }} animate={controls_name} transition={{ duration: 0.5 }}>
              <input {...register('name')} type="text" className="contact__form-input" placeholder="Name" />
            </motion.div>

            <motion.div ref={email_ref} className="contact__form-div" initial={{ y: -50 }} animate={controls_email} transition={{ duration: 0.5 }}>
              <input {...register('email')} type="email" className="contact__form-input" placeholder="Email" />
            </motion.div>
          </div>

          <motion.div ref={subject_ref} className="contact__form-div" initial={{ y: -50 }} animate={controls_subject} transition={{ duration: 0.5 }}>
            <input {...register('subject')} type="text" className="contact__form-input" placeholder="Subject" />
          </motion.div>

          <motion.div ref={message_ref} className="contact__form-div contact__form-area" initial={{ y: -50 }} animate={controls_message} transition={{ duration: 0.5 }}>
            <textarea {...register('message')} cols="30" rows="10" className="contact__form-input" placeholder="Message"></textarea>
          </motion.div>

          <motion.button type="submit" ref={button_ref} className="btn" initial={{ y: -50 }} animate={controls_button} transition={{ duration: 0.5 }}>
            Send Message
          </motion.button>
        </form>
      </div>
    </section>
  );
};

export default Contact;
