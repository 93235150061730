import React, { useRef } from 'react';
import { motion } from 'framer-motion';
import useScrollAnimations from '../../utils/hooks';
import './resume.css';
import Data from './Data';
import Card from './Card';

const Resume = () => {
  const experience_header_ref = useRef(null);
  const education_ref = useRef(null);
  const experience_ref = useRef(null);

  const { controls: controls_header } = useScrollAnimations(experience_header_ref);
  const { controls: controls_education } = useScrollAnimations(education_ref);
  const { controls: controls_experience } = useScrollAnimations(experience_ref);

  return (
    <section className="resume container section" id="resume">
      <motion.h2 ref={experience_header_ref} className="section__title" initial={{ y: -50 }} animate={controls_header} transition={{ duration: 0.5 }}>
        Experience
      </motion.h2>

      <div className="resume__container grid">
        <motion.div ref={education_ref} className="timeline grid" initial={{ y: -50 }} animate={controls_education} transition={{ duration: 0.5 }}>
          {Data.map((val, id) => {
            if (val.category === 'education') {
              return <Card key={id} icon={val.icon} title={val.title} year={val.year} desc={val.desc} />;
            }
            return null;
          })}
        </motion.div>

        <motion.div ref={experience_ref} className="timeline grid" initial={{ y: -50 }} animate={controls_experience} transition={{ duration: 0.5 }}>
          {Data.map((val, index) => {
            if (val.category === 'experience') {
              return <Card key={index} icon={val.icon} title={val.title} company={val.company} year={val.year} desc={val.desc} />;
            }
            return null;
          })}
        </motion.div>
      </div>
    </section>
  );
};

export default Resume;
